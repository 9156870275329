import moment from 'moment';
import { dimensionsLabel, metricsLabels } from '../constants';
import { FormData } from './types';

export const defaultMetrics = ['bids', 'impressions', 'clicks', 'ctr', 'cpm', 'cpc', 'totalSpend'];

export const initialValues: FormData = {
  hideMediaSpend: false,
  campaignIds: [],
  lineIds: [],
  lineCreativeIds: [],
  countries: [],
  startDate: moment().subtract(31, 'day').toDate(),
  endDate: moment().subtract(1, 'day').toDate(),
  dimensions: 'campaignName',
  displayCharts: ['impressions', 'mediaSpend'],
  displayColumns: ['bids', 'impressions', 'clicks', 'ctr', 'cpm', 'cpc'],
  timeSplit: 'day',
};

export const baseMetrics = [
  {
    label: metricsLabels['bids'],
    value: 'bids',
  },
  {
    label: metricsLabels['impressions'],
    value: 'impressions',
  },
  {
    label: metricsLabels['clicks'],
    value: 'clicks',
  },
  {
    label: metricsLabels['buys'],
    value: 'buys',
  },
  {
    label: metricsLabels['registrations'],
    value: 'registrations',
  },
  {
    label: metricsLabels['subscriptions'],
    value: 'subscriptions',
  },
  {
    label: metricsLabels['funnelStep1'],
    value: 'funnelStep1',
  },
  {
    label: metricsLabels['funnelStep2'],
    value: 'funnelStep2',
  },
  {
    label: metricsLabels['funnelStep3'],
    value: 'funnelStep3',
  },
  {
    label: metricsLabels['installs'],
    value: 'installs',
  },
  {
    label: metricsLabels['landings'],
    value: 'landings',
  },
  {
    label: metricsLabels['views'],
    value: 'views',
  },
  {
    label: metricsLabels['firstQuartileViews'],
    value: 'firstQuartileViews',
  },
  {
    label: metricsLabels['midPointViews'],
    value: 'midPointViews',
  },
  {
    label: metricsLabels['thirdQuartileViews'],
    value: 'thirdQuartileViews',
  },
  {
    label: metricsLabels['completedViews'],
    value: 'completedViews',
  },
  {
    label: metricsLabels['otherConversions'],
    value: 'otherConversions',
  },
];

export const costMetrics = [
  {
    label: metricsLabels['mediaSpend'],
    value: 'mediaSpend',
  },
  {
    label: metricsLabels['thirdPartySpend'],
    value: 'thirdPartySpend',
  },
  {
    label: metricsLabels['totalSpend'],
    value: 'totalSpend',
  },
  {
    label: metricsLabels['cpc'],
    value: 'cpc',
  },
  {
    label: metricsLabels['cpm'],
    value: 'cpm',
  },
];

export const rateMetrics = [
  {
    label: metricsLabels['ctr'],
    value: 'ctr',
  },
];

export const metricsOptions = [
  // Base metrics
  ...baseMetrics,
  // Cost metrics
  ...costMetrics,
  // Rate metrics
  ...rateMetrics,
];

export const dimensionsOptions = [
  {
    label: dimensionsLabel['accountId'],
    value: 'accountId',
  },
  {
    label: dimensionsLabel['accountName'],
    value: 'accountName',
  },
  {
    label: dimensionsLabel['adSize'],
    value: 'adSize',
  },
  {
    label: dimensionsLabel['advertiserBundle'],
    value: 'advertiserBundle',
  },
  {
    label: dimensionsLabel['advertiserDomain'],
    value: 'advertiserDomain',
  },
  {
    label: dimensionsLabel['agencyId'],
    value: 'agencyId',
  },
  {
    label: dimensionsLabel['agencyName'],
    value: 'agencyName',
  },
  {
    label: dimensionsLabel['campaignId'],
    value: 'campaignId',
  },
  {
    label: dimensionsLabel['campaignName'],
    value: 'campaignName',
  },
  {
    label: dimensionsLabel['country'],
    value: 'country',
  },
  {
    label: dimensionsLabel['creativeId'],
    value: 'creativeId',
  },
  {
    label: dimensionsLabel['creativeName'],
    value: 'creativeName',
  },
  {
    label: dimensionsLabel['creativeSize'],
    value: 'creativeSize',
  },
  {
    label: dimensionsLabel['creativeType'],
    value: 'creativeType',
  },
  {
    label: dimensionsLabel['lineCreativeId'],
    value: 'lineCreativeId',
  },
  {
    label: dimensionsLabel['lineId'],
    value: 'lineId',
  },
  {
    label: dimensionsLabel['lineName'],
    value: 'lineName',
  },
];
